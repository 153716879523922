import React from 'react'

import Heading from './Job/components/Heading'
import Job from './Job/Job'
import "./Jobs.css"

const Jobs = () => {
    const jobs = ['Graphic Designer', 'Developer', 'Marketing & Sales']
   
    return (
        <main className="content">
           <Heading/>
            <ul>
               {jobs.map((job) => <Job title={job}/>)}
            </ul>
        </main>
    )
}

export default Jobs