
import "./TitleCard.css"

const TitleCard = () => {
    return (
        <div className="layout-grid layout-grid-hero">
            <section className="card  media ">
                <div className="tw-flex">
                    <h1 className="heading">Areesh, Welcome to Jobs of Maldives</h1>
                    <p className="link">
                        <a className="sucess" href="https://www.pandagreat.com">
                            Discover Maldives Hospitalty Jobs
                        </a>
                    </p>
                    <p className="link">
                        <a className="status" target="_blank" rel="noreferrer" href="https://www.pandagreat.com">
                        Want personalized job recommendations?
                        </a>
                    </p>
                </div>
                <footer className="bttn-container">
                    <a className="btn button" href="https://www.pandagreat.com">Log In</a>
                    <a className="btn button" href="https://www.pandagreat.com">Sign Up</a>
                </footer>
            </section>
        </div>
    )
}

export default TitleCard