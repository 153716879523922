import React from 'react'
import './NavBar.css'
import Logo from '../../assets/pandaLogo.svg'

const NavBar = () => {
    return(
        <header className="App-header">
            <div className="header-container">
                <div className="header-logo-title">
                    <img src={Logo} className="header-logo" alt="logo"></img>
                    <h2 className=" header-title">
                        Jobs of Maldives
                    </h2>
                </div>

                <button className="btn header-joinButton">Join</button>
            </div>

            <div className="header-tab-container">
                <button className="btn header-tab active hov">For you</button>
                <button className="btn header-tab hov">Resume</button>
                <button className="btn header-tab hov">Settings</button>
            </div>
        </header>
    )
}

export default NavBar