
import './App.css';

import Layout from './components/Layout/Layout';
import Jobs from './components/Jobs/Jobs';
import TitleCard from './components/TitleCard/TitleCard';

const App = () => {
  return (
    <Layout>
      <div className='center-container'>
        <TitleCard/>
        <Jobs/>
      </div>
    </Layout>
  );
}

export default App;
