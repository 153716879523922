import React from 'react'


import './JobTitle.css'

const JobTitle = (title) => {
    return (
        <div className="title-column">
            <li className="job-title">Looking for {title.title}</li>
            <p className="job-sub-title">Company Name</p>
        </div>
    )
}

export default JobTitle