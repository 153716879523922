import React from 'react'
import "./Job.css"
import JobIcon from './components/JobIcon'
import JobTitle from './components/JobTitle'
import DetailText from './components/DetailText'
import Button from './components/button'


import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

const Job = (title) => {
    return (
        <div className="list-container py m">
            <JobIcon className="list-job-icon"/>
            <JobTitle classname="list-job-title" title={title.title} />
            <DetailText className="list-job-detail"/>
            <DetailText className="list-job-detail"/>
            <BookmarkBorderOutlinedIcon color="disabled"/>
            <Button className="applyButton"/>
        </div>
    )
}

export default Job