import * as React from 'react'
import NavBar from '../Ui/NavBar'
import Footer from '../Ui/Footer'

const Layout = ({children}) => {
    return(
        <div className="App-container">
            <NavBar/>
            <div className="container">
                <div>
                    {children}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Layout