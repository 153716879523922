import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <footer className="App-footer">
            <p className="text-primary"> &copy; 2021 Jobs of Maldives</p>
        </footer>
    )
}

export default Footer;