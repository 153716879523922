

import './Heading.css'
import SearchIcon from '@mui/icons-material/Search';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const Heading = () => {
    return(
        <div className="list-header">
            <h2 className="list-heading">Recomended for you!</h2>


            <div className="icon-group">
                <SearchIcon className="icon"/>
                <div className="divider">
                    <svg width="2" height="31" >
                        <rect x="0" y="0" width="2" height="30" fill="#646e72"/>
                    </svg>
                </div>
                <BookmarkIcon className="icon"/>
            </div>
        </div>
    )
}

export default Heading