import React from 'react'
import './DetailText.css'

const DetailText = () => {
    return (
        <div className="detail-column">
            <p className="icon-text">Location</p>
            <p className="icon-detail">Remote</p>
        </div>
    )
}

export default DetailText