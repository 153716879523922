import React from 'react'
import './JobIcon.css'


const JobIcon = () => {
    return(
        <div className="job-icon">
            
        </div>
    )
}

export default JobIcon